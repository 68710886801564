<template>
  <div>
    <el-card style="width: 30pc;margin-left: 30pc">
      <el-form :model="chbForm" :label-position="labelPosition" label-width="200px">
        <el-form-item :label="$t('msg.chb.clearanceBOL')" prop="clearanceBOL" required >
          <el-input-number v-model="chbForm.clearanceBOL" :min="0" :step="1"/>
        </el-form-item>
        <el-form-item :label="$t('msg.chb.isfBOL')" prop="isfBOL" required  >
          <el-input-number v-model="chbForm.isfBOL" :min="0" :step="1"/>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item :label="$t('msg.chb.isfBondBOL')" prop="isfBondBOL" required >
          <el-input-number v-model="chbForm.isfBondBOL" :min="0" :step="1"/>
        </el-form-item>
        <el-form-item :label="$t('msg.chb.htsLines')" prop="htsLines" required >
          <el-input-number v-model="chbForm.htsLines" :min="0" :step="1"/>
        </el-form-item>
        <el-form-item :label="$t('msg.chb.ogaNum')" prop="ogaNum" required >
          <el-input-number v-model="chbForm.ogaNum" :min="0" :step="1"/>
        </el-form-item>
        <el-form-item :label="$t('msg.chb.annualBond')" prop="annualBond">
          <el-input-number v-model="chbForm.annualBond" :min="0" :step="1" :precision="2"/>
        </el-form-item>
        <el-divider></el-divider>
        <el-button type="primary" @click="getCHBQuote()" >{{$t('msg.rate.getQuote')}}</el-button>
      </el-form>
    </el-card>
    <el-card v-show="chbQuoteShow">
      <el-table :data="chbQuotes">
        <el-table-column prop="ctrlName" label="Item"></el-table-column>
        <el-table-column prop="ctrlNumber" label="Cost"></el-table-column>
      </el-table>
      <el-collapse v-model="collapse" accordion>
        <el-collapse-item name="1">
          <template #title >
            <span style="color: #0ea5e9">{{$t('msg.dray.reviewFullCard')}}</span>
          </template>
          <el-table :data="simpleRateTemp">
            <el-table-column prop="item" label="item"></el-table-column>
            <el-table-column prop="type" label="Type"></el-table-column>
            <el-table-column prop="ratePerUOM" label="ratePerUOM"></el-table-column>
            <el-table-column prop="uom" label="uom"></el-table-column>
            <el-table-column prop="baseCharge" label="baseCharge"></el-table-column>
            <el-table-column prop="chargeCap" label="chargeCap"></el-table-column>
            <el-table-column prop="currency" label="currency"></el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'chbQuote',
  setup() {
    const chbQuoteShow = ref(false)
    const chbForm = ref({ clearanceBOL: 1, isfBOL: 1, isfBondBOL: 0, htsLines: 1, ogaNum: 1, annualBond: 0 })
    const collapse = ref('')
    const labelPosition = ref('left')
    const simpleRateTemp = ref([])
    const chbQuotes = ref([])
    const lspName = ref('SurpathL2')
    const pod = ref('LGB')
    const getCHBQuote = () => {
      const param = 'lspName=' + lspName.value + '&pod=' + pod.value + '&clearanceBOL=' + chbForm.value.clearanceBOL +
        '&isfBOL=' + chbForm.value.isfBOL + '&isfBondBOL=' + chbForm.value.isfBondBOL + '&htsLines=' + chbForm.value.htsLines +
        '&ogaNum=' + chbForm.value.ogaNum + '&annualBond=' + chbForm.value.annualBond
      const reqURL = process.env.VUE_APP_BASE + '/chb/getCHBCost'
      axios.post(reqURL, param).then((response) => {
        simpleRateTemp.value = response.data.rateList
        chbQuotes.value = response.data.quoteList
        chbQuoteShow.value = true
        console.log(chbQuotes.value)
      }).catch((response) => {
        console.log(response)
      })
    }
    return { chbQuoteShow, chbForm, labelPosition, collapse, simpleRateTemp, chbQuotes, lspName, pod, getCHBQuote }
  }
})
</script>
